<!DOCTYPE html>

<html>
  <head>
    <link href='http://fonts.googleapis.com/css?family=Lato:100,300,400,300italic' rel='stylesheet' type='text/css'>
    <meta name="viewport" content="width=device-width, initial-scale=1">

  </head>
    <body >

      <!-- <div class="header-container" >

        <div id="header-desktop" *ngIf="!isMobile">
          Desktop Device
        </div>

        <div id="header-mobile" *ngIf="isMobile">
         Sample Device
        </div>

      </div> -->
      <div  class="mainDiv" *ngIf="!isMobile">
        <nav>
          <div class="row">
          <img class="logo allBttns" src="./assets/img/logotus.png">
          <!-- <div class="adBanner2">
            <img class="allBttns" (click)=redirect2() src={{longBannerURL}}>
          </div> -->
              <ul class="mainNav">
                  <li>
                      <a >HOME</a>
                  </li>

                   <li>
                       <a (click)="showMenuBar()">ESTACIONES</a> <div *ngIf="showMenu" class="estacionesList">
                        <mat-grid-list cols="1" rowHeight="2:1">
                          <mat-grid-tile (click)="navigate('top40')">
                          Top 40
                          </mat-grid-tile>
                          <mat-grid-tile (click)="navigate('tropical')" >Tropical</mat-grid-tile>
                          <mat-grid-tile (click)="navigate('urbano')">Urbano</mat-grid-tile>
                          <mat-grid-tile (click)="navigate('positive')">Positive</mat-grid-tile>
                          <mat-grid-tile (click)="navigate('mexicano')">Regional Mexicano</mat-grid-tile>
                          <mat-grid-tile (click)="navigate('fuzed')">Fuzed</mat-grid-tile>

                        </mat-grid-list>

                      </div></li>

                   <li>
                      <a href="https://www.youtube.com/c/TusExitosFmTV/videos" target="_blank"  >Entrevistas</a>
                  </li>



              </ul>
          </div>
      </nav>

<div class="headerPic">
  <div class="row">

    <figure>
      <!-- <img class="fotoheader" src="./assets/img/fotoHeaderCropped.png"> -->
      <img  class="fotoheader" src="./assets/img/Banner Web 3.jpg">
    </figure>

    <!-- <img class="tus-xitos-fm-title" src="./assets/img/Tus Éxitos FM title.png" />
    <div class="homeabout">
      Tus Éxitos FM es una plataforma que te trae los éxitos más sobresalientes de la música latina.
      Te ofrecemos noticias e información de interés en el mundo del espectáculo.
      Contamos con 3 estaciones de música en los formatos Top 40, Tropical y Urbano donde tienes la variedad de música sin comerciales.
      Descarga nuestro app móvil para que nos lleves a todos lados.
    </div>
    <img class="arrowhead" src="./assets/img/arrow-head@1x.png" />
    <h2 class="dale-play">Dale play a tu favorita</h2> -->


        <img class="logo-tus-exitos-head" (click)="navigate('top40')" src="./assets/img/icon-ionic-ios-play-circle@1x.png"/>
        <!-- <img class="logo-tropical-head" (click)="navigate('tropical')" src="./assets/img/logo-tropical.png" />
        <img class="logo-urbano-head" (click)="navigate('urbano')" src="./assets/img/logo-urban.png" />
        <img class="logo-positive-head" (click)="navigate('positive')" src="./assets/img/logoPositive.png" /> -->


  </div>

</div>

<div class="socialIcons clearFix">
  <div >

      <img class="allBttns icon" src="./assets/img/icon-fb@1x.png" />

  </div>
  <div>

      <img class="allBttns icon" src="./assets/img/icon-youtube@1x.png" />

  </div>
  <div>

      <img class="allBttns icon" src="./assets/img/icon-twitter@1x.png" />

  </div>
  <div >

      <img class="allBttns icon" src="./assets/img/insta.png" />

  </div>

</div>
<!-- <div class="adBanner">
  <img class="allBttns" (click)=redirect() src={{AdPic}}>
</div> -->
<div class="adBanner">
  <img class="allBttns" (click)=redirect2() src={{longBannerURL}}>
</div>
<div class="newAD" style="position:fixed;" *ngIf=this.cancel><img (click)="redirect3()" src={{newPic}} >
<div *ngIf="newPic != undefined" (click)=changeCancel() class=cancel style="position: absolute; top:5%; left: 36.5%;text-align: center; cursor: pointer">X</div>
</div>
<div class="player">

  <iframe allowfullscreen src="https://server40.servistreaming.com/hybrid-stream-video-widget/tusexitosfmtv" height="100%" width="60%" frameBorder="0"></iframe>
</div>

<div class="cardSection">

<div style="height:20px;"></div>
  <!-- <img class="allBttns" (click)=redirect() src={{AdPic}}> -->

  <!-- <div class="estacionesTitle row">Estaciones</div> -->

  <div style="margin-top:7%;">
  <div class="row stationCards col span-1-of-2">
    <img (click)="navigate('top40')" class="cards" src="./assets/img/top40SmallCard.png"/>
  </div>
  <div class="row stationCards col span-1-of-2">
    <img (click)="navigate('tropical')" class="cards" src="./assets/img/tropicalSmallCard.png"/>
  </div>

  <div class="row stationCards col span-1-of-2">
    <img (click)="navigate('urbano')" class="cards" src="./assets/img/urbanoSmallCard.png"/>
  </div>
  <div class="row stationCards col span-1-of-2">
    <img (click)="navigate('positive')" class="cards" src="./assets/img/Botones Tus Exitos 5.png"/>
  </div>

</div>
<div class="clearFix"></div>
<div style="margin-top:3%;">
  <div class="row stationCards col span-1-of-2">
    <img (click)="navigate('mexicano')" class="cards" src="./assets/img/boton-mexicano.png"/>
  </div>
  <div class="row stationCards col span-1-of-2">
    <a href="https://www.youtube.com/c/TusExitosFmTV/videos">
    <img  class="cards" src="./assets/img/Boton YouTube.png"/>
  </a>
  </div>

  

</div>
<div class="clearFix"></div>

<div style="margin-top:3%; text-align: center;">
  
  <div class=" stationCards "style="width: 48%; text-align: center;margin-right:auto;margin-left:auto;">
    <a (click)="navigate('fuzed')"style="text-align:center">
    <img  class="cards" src="./assets/img/fuzedBoton.png">
  </a>
  </div>

  

</div>


<div class= "youtubeSection"  class="row" [attr.id]="idLink">

  <div class="youTitle row">
    <a class="botonEntrevistas" href="https://www.youtube.com/c/TusExitosFmTV/videos" target="_blank">
      <img  src="./assets/img/Logo De Tu A Tu_Logo 6.png" >

    </a>
  </div>

  <!-- <div class="videoContainer" *ngFor="let video of videos">
    <div class="video">





  <mat-card class="example-card">

    <a href="https://www.youtube.com/watch?v={{video.id.videoId}}" target="_blank">
      <img class="youtubePic" [src]="video.snippet.thumbnails.medium.url" class="" alt="..."></a>
      <mat-card-content>
        <div class="">
          <h5 class="videoTitle ">
          <a href="https://www.youtube.com/watch?v={{video.id.videoId}}">{{video.snippet.title}}
          </a></h5>

          </div>
    </mat-card-content>

  </mat-card>
</div>
  </div>
  <div class="more row">

    <a href="https://www.youtube.com/channel/UC8noIxhPi8MBK686-rCNUNA" target="_blank">

      Ver más...
    </a>
  </div>
  </div> -->

</div>
</div>

<footer class=footer>

<a style="margin-top:50px;" href="http://play.google.com/store/apps/details?id=com.tusexitos.fm" target="_blank">

  <img class="botonPlay allBttns" src="./assets/img/en_badge_web_generic.png">
</a>
<a href="https://apps.apple.com/us/app/tus-%C3%A9xitos-fm/id1536942461" target="_blank">

  <img class=" allBttns botonPlay" src="./assets/img/download-app-store-icon-10.jpg">
</a>
<div class="footer-text">
  Copyright © 2022 TusExitosFM
</div>
<div class="footer-text">
  Todos los derechos reservados
</div>
</footer>
  </div>


<!-- CELULAR VIEW -->
  <div class="cel"  *ngIf="isMobile">

    <div class="newADMobile" style="position:fixed;" *ngIf=this.cancel><img (click)="redirect3()" src={{newPic}} >
      <div *ngIf="newPic != undefined" (click)=changeCancel() class=cancel style="position: absolute; top:5%; left: 13%;text-align: center; cursor: pointer;font-size: 150%;">X</div>
      </div>

    <div style="height: 45px;"></div>
    <!-- HEADER -->
    <div class="titleSection">
      <div class= "row adBannerMobile" style="margin-top: 0px; align-items: center;">



            <img class="allBttns" style="object-fit: cover;" src="{{AdPic }}" (click)="redirect()">



      </div>
    </div>

    <!-- STATIONS -->

    <div class="playerMobile">

      <iframe allowfullscreen src="https://server40.servistreaming.com/hybrid-stream-video-widget/tusexitosfmtv" height="100%" width="80%" frameBorder="0"></iframe>
    </div>
        <div  class = "mobileCard row">
            <div (click)="navigate('top40')">
              <img class="allBttns" src="./assets/img/top40SmallCard.png">
            </div>

        </div>


        <div class = "row mobileCard">
            <div (click)="navigate('tropical')">
              <img class="allBttns" src="./assets/img/tropicalSmallCard.png">
            </div>
        </div>


        <div class = "row mobileCard">
            <div (click)="navigate('urbano')">
              <img class = "allBttns" src="./assets/img/urbanoSmallCard.png">
            </div>
          </div>
        <div class = "row mobileCard">
            <div (click)="navigate('positive')">
              <img class="allBttns" src="./assets/img/Botones Tus Exitos 5.png">
            </div>
          </div>
        <div class = "row mobileMex">
            <div (click)="navigate('mexicano')">
              <img class = allBttns src="./assets/img/boton-mexicano.png">
            </div>
          </div>
        <div class = "row mobileCard">
            <div (click)="navigate('tabs/tab3')">
              <img class = allBttns src="./assets/img/Boton YouTube.png">
            </div>
          </div>
        <div class = "row mobileCard">
            <div (click)="navigate('fuzed')">
              <img class = allBttns src="./assets/img/fuzedBoton.png">
            </div>
          </div>



          <!-- <iframe style="border-radius:12px" src="https://open.spotify.com/embed/playlist/3edFcIIEUDgqCyEGJP3dNT?utm_source=generator&theme=0" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe> -->



          <footer class=footer>

            <a style="margin-top:50px;" href="http://play.google.com/store/apps/details?id=com.tusexitos.fm" target="_blank">

              <img class="allBttns botonPlay" src="./assets/img/en_badge_web_generic.png">
            </a>
            <a href="https://apps.apple.com/us/app/tus-%C3%A9xitos-fm/id1536942461" target="_blank">

              <img class="allBttns botonPlay" src="./assets/img/download-app-store-icon-10.jpg">
            </a>
            <div class="footer-text">
              Copyright © 2022 TusExitosFM
            </div>
            <div class="footer-text">
              Todos los derechos reservados
            </div>
            </footer>

  </div>
</body>
</html>