import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/Services/data.service';
import {Howl, Howler} from 'howler';
import { Router, RouterModule } from "@angular/router";


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
 // play: boolean = true;
  song :any;
  pic : boolean = false;
  stationUrl = "http://146.71.118.220:35022/live"
  stationName = "Top40"
  songs: any[];
  picUrl : string;
  constructor( private dataservice :DataService, private router: Router) {}

  ngOnInit(): void {
 //   this.play = true;
  console.log("Top40");
  // this.dataservice.Top40.play();
  //this.dataservice.Urbano.stop()
  //this.dataservice.Tropical.stop()
  // this.dataservice.stationPlaying = this.stationName;
  //  this.getLastSongs();


   //   this.getPlayTrack();


   this.dataservice.setStations();

  }

play40(){
  this.dataservice.Top40.play();
}
stop40(){
 this.dataservice.Top40.stop();

}
  getPlayTrack(){

    this.dataservice.getTop40Info().subscribe(
      data => {
        let tempSong : any[];
        tempSong = data["data"];
        this.song = tempSong[0].track
        console.log(this.song);

        if(this.song.album == "Unknown" ||this.song.album == "" ){
          this.picUrl = '/assets/images/album-top40.png'
          this.pic = true;
          console.log(this.picUrl);
        }

        else{
         // this.MakePic();

        }

      },

      err => {
        console.log(err);
      },
      () => {
        console.log('done');

      }
      );

    }


navigate(page: string){
console.log("navigate")

this.router.navigate(['/' + page]);


}


}
