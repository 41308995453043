import { Component, OnInit, ViewEncapsulation  } from '@angular/core';
import { BlogService } from 'src/app/Services/blog.service';
import { Router, RouterModule,ActivatedRoute } from "@angular/router";
import { DataService } from 'src/app/Services/data.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css'],
})
export class BlogComponent implements OnInit {

  post: any;
  singlePost: boolean = false;
  interval;
  showMenu: boolean = false;
  timeLeft: number = 7;
  loading: boolean = true;

  posts = []; //array for wordpress posts
  page = 1; //infinite scrolling variable
  count = null;
  AdPic: any;
  AdPicUrl: string;

  constructor(private blog: BlogService,  private router: Router, private route: ActivatedRoute, private dataservice: DataService) {
     this.AdPic = this.dataservice.PicUrl;}

  ngOnInit(): void {
    this.dataservice.setStations();
    this.loadPosts();
    this.startTimer();
    //this.loadMore(event);
  }

  //Navigate to stations
  goToStation(station: string)
  {

    this.router.navigate(['/' + station])

  }

  ngAfterViewInit(){
     setInterval(() => this.getPic() , 5000); // TIMER TO SEARCH ADPIC
  }




//get post from wordpress and assign. Loading.

   loadPosts() {

    this.blog.getPosts().subscribe(res => {
      this.count = this.blog.totalPosts;
      this.posts = res;

    });
  }

  //load post for infite loading
  loadMore(event) {
    this.page++;

    this.blog.getPosts(this.page).subscribe(res => {
      this.posts = [...this.posts, ...res];
      event.target.complete();

      // Disable infinite loading when maximum reached

    });
  }

  navigate(page: string){
    console.log("navigate")

    this.router.navigate(['/' + page]);


    }


    showMenuBar(){
      this.showMenu = !this.showMenu;
    }

presentSingle(postId:any){
  let id = postId;
  this.blog.getPostContent(id).subscribe(res=> {
    this.post = res;
});

this.singlePost = true;

}
getPic(){
 // this.AdPic = this.dataservice.slimPicUrl;
}

async redirect(){
 // let redirect : string = this.dataservice.slimPicRedirects
 // this.AdPicUrl = this.dataservice.slimPicRedirects[0];

 await window.open(this.AdPicUrl, "_blank");
  // let redirect : string = this.dataservice.PicRedirects
  // await Browser.open({ url: this.dataservice.PicRedirects[0]  });
}
startTimer() {
  this.interval = setInterval(() => {
    if(this.timeLeft > 0) {
      this.timeLeft--;
    } else {
      this.loading = false;
      clearInterval(this.interval);
    }
  },1000)
}
}
