<!DOCTYPE html>

<html>
<head>
  <link href='http://fonts.googleapis.com/css?family=Lato:100,300,400,300italic' rel='stylesheet' type='text/css'>

</head>
  <body >

    <div  class="mainDiv" *ngIf="!isMobile">
      <img class="greenline-C61RwL " src="./assets/img/red_line.png" />
      <nav>
        <div class="row">
        <img (click)="navigate('home')" class=" allBttns logo" src="./assets/img/logotus.png">



            <ul class="mainNav">
                <li>
                    <a (click)="navigate('home')">Home</a>
                </li>

                 <li>
                     <a (click)="showMenuBar()">Estaciones</a>

                     <div *ngIf="showMenu" class="estacionesList">
                       <mat-grid-list cols="1" rowHeight="2:1">
                         <mat-grid-tile (click)="navigate('top40')">
                         Top 40
                         </mat-grid-tile>
                         <mat-grid-tile >Tropical</mat-grid-tile>
                         <mat-grid-tile (click)="navigate('urbano')" >Urbano</mat-grid-tile>
                         <mat-grid-tile (click)="navigate('positive')" >Positive</mat-grid-tile>
                         <mat-grid-tile (click)="navigate('mexicano')" >Regional Mexicano</mat-grid-tile>
                         <mat-grid-tile (click)="navigate('fuzed')" >Fuzed</mat-grid-tile>
                         
                       </mat-grid-list>

                     </div>
                    </li>

                 <!-- <li>
                    <a (click)="navigate('entrevistas')">Entrevistas</a>
                </li> -->



            </ul>



        </div>


    </nav>

<div class="row">
  <div class="adBanner">
    <img  (click)=redirect() src={{longBannerURL}}>
  </div>
<div class="albumContainer" style="text-align: center;">
  <img *ngIf="pic" src="{{picUrl}}">
  <img *ngIf="!pic" src="./assets/img/album-tropical.png">


</div>

</div>

<div class="row" style="text-align: center;">
<div class="songInfo">
  <mat-spinner *ngIf="loading" ></mat-spinner>
  <h1 class="songName" *ngIf="pic">{{song.title}}</h1>
  <h2 class="artistName" *ngIf="pic">{{song.artist}}</h2>
  <h3 class="albumName" *ngIf="pic">{{albumName}}</h3>


</div>
</div>
<div class="tarjetas" >
  <div class="row">

    <img (click)="navigate('top40')" class="tarjetaTop40 tarjetaSola" src="./assets/img/top40SmallCard.png">

  </div>
  <div class="row">

    <img (click)="navigate('urbano')" class="tarjetaUrbano tarjetaSola" src="./assets/img/urbanoSmallCard.png">


  </div>
  <div class="row">



    <img  class="tarjetaTropical tarjetaSola" src="./assets/img/tropicalSmallCard.png">

  </div>
  <div class="row">



    <img (click)="navigate('positive')" class="tarjetaUrbano tarjetaSola" src="./assets/img/Botones Tus Exitos 5.png">

  </div>
  <div  class="row">



    <img (click)="navigate('mexicano')" class="tarjetaMexicano tarjetaSola" src="./assets/img/boton-mexicano.png">

  </div>
  <div  class="row">



    <img (click)="navigate('fuzed')" class="tarjetaFuzed tarjetaSola" src="./assets/img/fuzedBoton.png">

  </div>
</div>
<img
class="logoNavigation"
src="./assets/img/logo-tropical.png"
/>
<div class="footer">

<img *ngIf="play" class="allBttns play-pause"  src="./assets/img/pauseTropi.png" (click)="playPause()">
<img *ngIf="!play"  class="allBttns play-pause"  src="./assets/img/icon-ionic-ios-play-circle-1@1x.png" (click)="playPause()">

  <!-- <svg-icon src="./assets/img/pause_circle_filled-24px.svg" [svgStyle]="{ 'width.px':500 }"></svg-icon> -->
</div>
</div>
<!--MOBILE-->

<div class="mobileDiv" *ngIf="isMobile">

  <nav>
    <div class="row">
      <div class="logoMobileCont">

        <img (click)="navigate('home')" class="logoMobile allBttns" src="./assets/img/logo-tropical.png">
      </div>


        <ul class="mainNavMobile">
            <li>
                <a (click)="navigate('home')">Home</a>
            </li>

             <li>
                 <a (click)="showMenuBar()">Estaciones</a>
                 <div *ngIf="showMenu" class="estacionesList">
                   <mat-grid-list cols="1" rowHeight="2:1">
                     <mat-grid-tile (click)="navigate('top40')">
                       Top 40
                     </mat-grid-tile>
                     <mat-grid-tile >Tropical</mat-grid-tile>
                     <mat-grid-tile (click)="navigate('urbano')" >Urbano</mat-grid-tile>
                     <mat-grid-tile (click)="navigate('positive')" >Positive</mat-grid-tile>
                     <mat-grid-tile (click)="navigate('mexicano')" >Regional Mexicano</mat-grid-tile>


                    </mat-grid-list>

                  </div>
                </li>
<!--
             <li>
                <a (click)="navigate('entrevistas')">Entrevistas</a>
            </li> -->

        </ul>



    </div>


</nav>

<div class="albumContainer" style="text-align: center; margin-bottom: 0;">
  <div class="adBannerMobile">
    <img class="allBttns" (click)=redirect() src={{longBannerURL}}>
  </div>
  <img *ngIf="pic" src="{{picUrl}}">
  <img *ngIf="!pic" src="./assets/img/album-tropical.png">


</div>


<div class="row" style="text-align: center;">
  <div class="songInfo">
    <mat-spinner *ngIf="loading" ></mat-spinner>
    <!-- <mat-progress-spinner mode="indeterminate" >
    </mat-progress-spinner> -->
    <h1 class="songName" *ngIf="pic">{{song.title}}</h1>
    <h2 class="artistName" *ngIf="pic">{{song.artist}}</h2>
    <h3 class="albumName" *ngIf="pic">{{albumName}}</h3>


  </div>
  </div>

  <div class="footer">

    <img *ngIf="play" class="play-pause allBttns"  src="./assets/img/pauseTropi.png" (click)="playPause()">
    <img *ngIf="!play"  class="play-pause allBttns"  src="./assets/img/icon-ionic-ios-play-circle-1@1x.png" (click)="playPause()">

      <!-- <svg-icon src="./assets/img/pause_circle_filled-24px.svg" [svgStyle]="{ 'width.px':500 }"></svg-icon> -->
    </div>
    <div class="row" style="text-align: center;">

    </div>
</div>





  </body>
  </html>
