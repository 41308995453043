<!DOCTYPE html>
<html>

  <head>
    <link href='http://fonts.googleapis.com/css?family=Lato:100,300,400,300italic' rel='stylesheet' type='text/css'>


  </head>
  <body>

    <div class="mainDiv">

        <nav>
          <div class="row">
          <img class="logo" src="./assets/img/logotus.png">


          </div>
      </nav>
      <div class=" row arrowCont" >

        <div  class="back" (click)="goBack()">Regresar</div>
      </div>
<div class="singlePost" >

      <div class="singlePostTitle row"style="margin-top: 1%;" [innerHTML]=post.title.rendered >{{post?.title.rendered}}</div>
      <div class="postDate">{{post.date_gmt | date}}</div>
      <div class="row">
        <img class="SinglePostImg" [src]="post.media_url" >

      </div>
      <div class="row contenido" >


        <div  [innerHTML]=post.content.rendered padding>{{post.content.rendered}}</div>
      </div>

      <div class="row bttncontainer">
        <button class="backBttn" (click)="goBack()">Regresar</button>

      </div>
    </div>
  </div>
  </body>

</html>
