import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HttpClientModule } from "@angular/common/http";
import { Top40Component } from './top40/top40.component';
import { TropicalComponent } from './tropical/tropical.component';
import { UrbanoComponent } from './urbano/urbano.component';
import { Tusexitos40Component } from './tusexitos40/tusexitos40.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatListModule} from '@angular/material/list';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner'
import {MatIconModule} from '@angular/material/icon';

import { BlogComponent } from './blog/blog.component';
import { CasaComponent } from './casa/casa.component';

import{ AngularFireModule } from'@angular/fire';
import{ AngularFireStorageModule } from'@angular/fire/storage';
import{ AngularFireDatabaseModule } from'@angular/fire/database';
import { SinglePostComponent } from './single-post/single-post.component';
import { PositiveComponent } from './positive/positive.component';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { MexicanoComponent } from './mexicano/mexicano.component';
import { FuzedComponent } from './fuzed/fuzed.component';

const firebaseConfig = {
  apiKey: "AIzaSyBmjgwNgJ706PTamPeBi7FwLpcjfeSvEeI",
  authDomain: "tus-exitos-fm-e2bc4.firebaseapp.com",
  databaseURL: "https://tus-exitos-fm-e2bc4.firebaseio.com",
  projectId: "tus-exitos-fm-e2bc4",
  storageBucket: "tus-exitos-fm-e2bc4.appspot.com",
  messagingSenderId: "937646576251",
  appId: "1:937646576251:web:b6cbc41a8d9de5d11b48de"
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    Top40Component,
    TropicalComponent,
    UrbanoComponent,
    Tusexitos40Component,
    BlogComponent,
    CasaComponent,
    SinglePostComponent,
    PositiveComponent,
    MexicanoComponent,
    FuzedComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,AngularSvgIconModule.forRoot(), MatListModule, BrowserAnimationsModule, MatSliderModule, MatGridListModule, MatCardModule,MatProgressSpinnerModule,  AngularFireModule.initializeApp(firebaseConfig),
    AngularFireDatabaseModule, AngularFireStorageModule,MatIconModule, RouterModule
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
