
<!DOCTYPE html>
<html>


<body>
<div class="mainDiv">
  <nav>
    <div class="row">
    <img class="logo" src="./assets/img/logotus.png">

        <ul class="mainNav">
            <li>
                <a (click)="navigate('home')" >Home</a>
            </li>

             <li>
                 <a (click)="showMenuBar()">Estaciones</a> <div *ngIf="showMenu" class="estacionesList">
                  <mat-grid-list cols="1" rowHeight="2:1">
                    <mat-grid-tile (click)="navigate('top40')">
                    Top 40
                    </mat-grid-tile>
                    <mat-grid-tile (click)="navigate('tropical')" >Tropical</mat-grid-tile>
                    <mat-grid-tile (click)="navigate('urbano')">Urbano</mat-grid-tile>

                  </mat-grid-list>

                </div></li>

             <li>
                <a >Blog</a>
            </li>



        </ul>
    </div>
</nav>









  <!-- <mat-list *ngFor="let post of posts">
    <mat-list-item >
      <div class= row>
<div>
  <mat-card>
    <mat-card-header>
      <mat-card-subtitle >{{ post.date_gmt | date }}</mat-card-subtitle>
      <mat-card-title [innerHTML]="post.title.rendered"></mat-card-title>
    </mat-card-header>
    <img mat-card-image [src]="post.media_url">
    <mat-card-actions>
      <button class="readMore">Read More</button>
    </mat-card-actions>


  </mat-card>

</div>

      </div>
      </mat-list-item>


  </mat-list> -->

<div class="row" style="margin: 0;">
  <div class="adBanner">
    <img (click)=redirect() src={{AdPic}}>
  </div>
</div>
  <div class="blogPosts" *ngIf="!singlePost">
    <div class="estacionesTitle row">Blog - Tus Exitos FM</div>

    <div class="row">


      <div class="postContainer">

        <ul class="postList" *ngFor="let post of posts">
          <li>
          <img class="postImg" [src]="post.media_url">
          <div class="postDate">{{post.date_gmt | date}}</div>
          <div class="postTitle" [innerHTML]="post.title.rendered"></div>
          <a class="botonMore" [routerLink]="['/blogpost/',  post.id]">Ver más</a>
         </li>
          </ul>

      </div>

    </div>

  <div class="row" style="text-align: center;">
    <button (click)= "loadMore($event)" class="bttnAll">Cargar Más</button>
  </div>



  </div>



  <!-- <ng-template #longContent let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Modal title</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
      <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
      <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
      <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
      <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
      <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
      <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
      <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
      <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
      <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
      <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
      <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
      <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
      <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
      <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
      <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
      <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
      <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
  </ng-template> -->


</div>
</body>



</html>
