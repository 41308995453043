import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { HomeComponent } from "./home/home.component";
import { Top40Component } from "./top40/top40.component";
import { TropicalComponent } from "./tropical/tropical.component";
import { UrbanoComponent } from "./urbano/urbano.component";
import {Tusexitos40Component } from "./tusexitos40/tusexitos40.component";
import {BlogComponent } from "./blog/blog.component";
import { CasaComponent } from "./casa/casa.component";
import { SinglePostComponent } from "./single-post/single-post.component";
import { PositiveComponent } from "./positive/positive.component";
import { MexicanoComponent } from "./mexicano/mexicano.component";
import { FuzedComponent } from "./fuzed/fuzed.component";

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
};


const routes: Routes = [
                          // { path: 'home', component: HomeComponent },
                          {path: 'tropical', component: TropicalComponent},
                          {path: 'urbano', component: UrbanoComponent},
                          {path: 'top40', component: Tusexitos40Component},
                          {path: 'blog', component: BlogComponent},
                          {path: 'home', component: CasaComponent},
                          {path: '', component: CasaComponent},
                          {path: 'mexicano', component: MexicanoComponent},
                          {path: 'fuzed', component: FuzedComponent},

                          {path: 'positive', component: PositiveComponent},
                          {path: 'blogpost', component: SinglePostComponent},
                          {path: 'blogpost/:id', component: SinglePostComponent},
                          {path: "", redirectTo:'entrevistas', pathMatch:'full'},


];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
