import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/Services/data.service';
import { Router, RouterModule } from "@angular/router";

@Component({
  selector: 'app-top40',
  templateUrl: './top40.component.html',
  styleUrls: ['./top40.component.css']
})
export class Top40Component implements OnInit {
  play: boolean = true;
  song :any;
  pic : boolean = false;
  stationUrl = "http://146.71.118.220:35022/live"
  stationName = "Top40"
  albumName: string;
  songs: any[];
  picUrl : string;

  constructor(private dataservice :DataService, private router: Router) { }

  ngOnInit(): void {
    if(this.dataservice.stationPlaying != this.stationName){
      this.play40();

      this.dataservice.stationPlaying = this.stationName;

      this.getPlayTrack()

    }
  }

  ngAfterViewInit(){

    setInterval(() => this.getPlayTrack() , 1000);

  }
  play40(){
    this.dataservice.Top40.play();
    this.dataservice.Urbano.stop()
    this.dataservice.Tropical.stop()
  }
  stop40(){
   this.dataservice.Top40.stop();

  }
  playPause(){
    this.play = !this.play
    if(this.play == true){
      this.dataservice.Top40.play();
      //console.log('play')


    }
    else{
      //console.log('pause')
      this.dataservice.Top40.stop();

    }



  }
  getPlayTrack(){

    this.dataservice.getTop40Info().subscribe(
      data => {
        let tempSong : any[];
        tempSong = data["data"];
        this.song = tempSong[0].track
        //console.log(this.song);

        if(this.song.album == "Unknown" ||this.song.album == "" ){
          this.picUrl = '/assets/img/album-top40.png'
          this.pic = true;
          //console.log(this.picUrl);
          this.albumName = "Single";

        }

        else{
          this.MakePic();
          this.albumName = this.song.album;

        }

      },

      err => {
        //console.log(err);
      },
      () => {
        //console.log('done');

      }
      );

    }

    MakePic(){
      let tempString;
      tempString = this.song.imageurl
      //console.log("hello");
      tempString = tempString.slice(0,-13)
      //console.log(tempString);
      this.picUrl = tempString + "400x400bb.jpg"
     // console.log(this.picUrl);
      this.checkPic();


    }

    checkPic(){

      this.dataservice.CheckPic(this.picUrl).subscribe(
        data => {
          let tempSong : any[];
          tempSong = data["data"];

         // console.log(data);



        },

        err => {
          //console.log(err.status);
          if(err.status  != 200){
            this.picUrl = "/assets/img/album-top40.png"
           // console.log(this.picUrl);
            this.pic = true;}
            else{
              this.pic = true;
            }
          },
          () => {
           // console.log('done');

          }
          );

        }


        navigate(page: string){
         // console.log("navigate")

          this.router.navigate(['/' + page]);


          }
}



