import { Component, OnInit } from '@angular/core';
import { BlogService } from 'src/app/Services/blog.service';
import { Router, RouterModule,ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-single-post',
  templateUrl: './single-post.component.html',
  styleUrls: ['./single-post.component.css']
})
export class SinglePostComponent implements OnInit {

post: any;

  constructor(private route: ActivatedRoute, private blog: BlogService,  private router: Router ) { }

  ngOnInit(): void {
    let id=this.route.snapshot.paramMap.get('id');
    this.blog.getPostContent(id).subscribe(res=> {
        this.post = res;

    });
  }

  goBack(){

    this.router.navigate(['/blog']);
  }

}
