import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from "@angular/router";
import { DataService } from 'src/app/Services/data.service';
import {ResponsiveServiceService} from 'src/app/Services/responsive-service.service'
import {YoutubeService} from 'src/app/Services/youtube.service'
import { NgxSpinnerService  } from "ngx-spinner";
import { Observable, Subject, of, from, Subscription  } from 'rxjs';
import { map, tap, takeUntil} from 'rxjs/operators';


@Component({
  selector: 'app-casa',
  templateUrl: './casa.component.html',
  styleUrls: ['./casa.component.css']
})
export class CasaComponent implements OnInit {
  idLink: any = "content"
  showMenu: boolean = false;
  song :any;
  pic : boolean = false;
  stationName = "Top40"
  songs: any[];
  picUrl : string;
  AdPic: any;
  AdPicUrl: string;
  AdPicUrl2: string;
  AdPicUrl3: string;
  newPic: any;
  videos: any[];
  public isMobile: Boolean;
  longBannerURL: any;
  cancel: boolean = true
  private unsubscribe$: Subject<any> = new Subject();

  constructor(private router: Router, private dataservice :DataService,
    private responsiveService: ResponsiveServiceService, private youTubeService: YoutubeService,
    private spinner: NgxSpinnerService
    ) {  this.AdPic = this.dataservice.PicUrl;
      this.longBannerURL = this.dataservice.PicUrl2;
    this.newPic = this.dataservice.PicUrl3}

  ngOnInit(): void {
    this.dataservice.setEmpty();
    this.dataservice.setStations();
  this.onResize();
    this.responsiveService.checkWidth();
    this.setYoutube();
  
  }

  ngAfterViewInit(){

    setInterval(() => this.getPic() , 3000); // TIMER TO SEARCH ADPIC

  }

changeCancel(){

  this.cancel = false
}
  setYoutube(){

    this.spinner.show()
setTimeout(()=>
{
this.spinner.hide()
},3000)
this.videos = [];
this.youTubeService
.getVideosForChanel('UC8noIxhPi8MBK686-rCNUNA', 8)
.pipe(takeUntil(this.unsubscribe$))
.subscribe(lista => {
for (let element of lista["items"]) {
this.videos.push(element)
}
});
}


  getPic(){
    this.AdPic = this.dataservice.PicUrl;
    this.longBannerURL = this.dataservice.PicUrl2;
    this.newPic=this.dataservice.PicUrl3
    
  }
  getBannerURL(){    //funcion del banner slim------------------------------------------<
    this.longBannerURL = this.dataservice.PicUrl2;
  }
  getAdURL(){
this.newPic = this.dataservice.PicUrl3;


  }
  async redirect(){
    let redirect : string = this.dataservice.PicRedirects
    this.AdPicUrl = this.dataservice.PicRedirects[0];

   await window.open(this.AdPicUrl, "_blank");

  }
  async redirect2(){
    let redirect : string = this.dataservice.PicRedirects2
    this.AdPicUrl = this.dataservice.PicRedirects2[0];
    await window.open(this.AdPicUrl, "_blank");
  }

async redirect3(){
  let redirect : string = this.dataservice.picRedirects3
  this.AdPicUrl3 = this.dataservice.picRedirects3[0];
  await window.open(this.AdPicUrl3, "_blank");

}
  
  navigate(page: string){
   // console.log("navigate")

    this.router.navigate(['/' + page]);


    }
    showMenuBar(){
      this.showMenu = !this.showMenu;
    }



    //load post for infite loading


    onResize() {
      this.responsiveService.getMobileStatus().subscribe(isMobile => {
        this.isMobile = isMobile;
      });
    }

}
