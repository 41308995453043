<!DOCTYPE html>
<html>
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=1920, maximum-scale=1.0" />
    <link rel="shortcut icon" href="./img/favicon.png" />
    <meta name="og:type" content="website" />
    <meta name="twitter:card" content="photo" />
    <style>
      @import url("https://fonts.googleapis.com/css?family=Lato:700,300,400|Roboto:400");

      .component-wrapper a,
      .screen a {
        text-decoration: none;
        display: contents;
      }

      .full-width-a {
        width: 100%;
      }

      .full-height-a {
        height: 100%;
      }

      .screen textarea:focus,
      .screen input:focus {
        outline: none;
      }

      .screen *,
      .component-wrapper * {
        box-sizing: border-box;
      }

      .screen div {
        -webkit-text-size-adjust: none;
      }

      .container-center-vertical,
      .container-center-horizontal {
        pointer-events: none;
        display: flex;
        flex-direction: row;
        padding: 0;
        margin: 0;
      }

      .container-center-vertical {
        align-items: center;
        height: 100%;
      }

      .container-center-horizontal {
        justify-content: center;
        width: 100%;
      }

      .container-center-vertical > *,
      .container-center-horizontal > * {
        pointer-events: auto;
        flex-shrink: 0;
      }

      .component-wrapper,
      .screen {
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-all;
        word-break: break-word;
      }

      .auto-animated div {
        opacity: 0;
        position: absolute;
        --z-index: -1;
      }

      .auto-animated .container-center-vertical,
      .auto-animated .container-center-horizontal {
        opacity: 1;
      }

      .overlay {
        position: absolute;
        opacity: 0;
        display: none;
        top: 0;
        width: 100%;
        height: 100%;
        position: fixed;
      }

      .animate-appear {
        opacity: 0;
        display: block;
        animation: reveal 0.3s ease-in-out 1 normal forwards;
      }

      .animate-disappear {
        opacity: 1;
        display: block;
        animation: reveal 0.3s ease-in-out 1 reverse forwards;
      }

      .animate-nodelay {
        animation-delay: 0s;
      }

      @keyframes reveal {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
      .align-self-flex-start {
        align-self: flex-start;
      }
      .align-self-flex-end {
        align-self: flex-end;
      }
      .align-self-flex-center {
        align-self: center;
      }
      .valign-text-middle {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .valign-text-bottom {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
      input:focus {
        outline: none;
      }
      .component-wrapper,
      .component-wrapper * {
        pointer-events: none;
      }

      .component-wrapper a *,
      .component-wrapper a,
      .component-wrapper input,
      .component-wrapper video,
      .component-wrapper iframe,
      .listeners-active,
      .listeners-active * {
        pointer-events: auto;
      }

      .hidden,
      .hidden * {
        visibility: hidden;
        pointer-events: none;
      }

      .smart-layers-pointers,
      .smart-layers-pointers * {
        pointer-events: auto;
        visibility: visible;
      }

      .component-wrapper.not-ready,
      .component-wrapper.not-ready * {
        visibility: hidden !important;
      }

      .listeners-active-click,
      .listeners-active-click * {
        cursor: pointer;
      }
    </style>
    <meta name="author" content="AnimaApp.com - Design to code, Automated." />
  </head>
  <body style="margin: 0; ">
    <!-- <input type="hidden" id="anPageName" name="page" value="web-1920-1" /> -->
    <div class="container-center-horizontal">
      <div class="web-1920-1 screen" style="visibility: hidden;  pointer-events: none;">
        <!-- <button style="width: 300px; height: 200px; visibility: visible;  pointer-events: all;" (click)="navigate('top40')"></button> -->
        <div class="bggray-C61RwL"></div>
        <img class="headerpic01-C61RwL" src="./assets/img/bruce-mars-dbgwy7s3qy0-unsplash@1x.png" />
        <div class="social-media-C61RwL">
          <img class="iconfb-sBTGJr" src="./assets/img/icon-fb@1x.png" />
          <img class="iconyoutube-sBTGJr" src="./assets/img/icon-youtube@1x.png" />
          <img class="icontwitter-sBTGJr" src="./assets/img/icon-twitter@1x.png" />
          <div class="iconinsta-sBTGJr">
            <img class="instapart2-gDJuoC" src="./assets/img/insta-part2@1x.png" />
            <img class="instapart1-gDJuoC" src="./assets/img/insta-part1@1x.png" />
          </div>
        </div>
        <img class="tus-xitos-fm-C61RwL" src="./assets/img/tus--xitos-fm@1x.png" />
        <img class="estaciones-C61RwL" src="./assets/img/estaciones@1x.png" />
        <div class="homeabout-C61RwL">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
          dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
          clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
          consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
          takimata sanctus est Lorem ipsum dolor sit amet.<br />Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
          sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos
          et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
          ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
          invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
          dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.<br />Lorem
          ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
          magna
        </div>

        <img class="arrowhead-C61RwL" src="./assets/img/arrow-head@1x.png" />
        <div class="dale-play--u-favorita-C61RwL">Dale play a tu favorita</div>
        <a (click)="navigate('tropical')" style="pointer-events: all;">

          <img class="logotropicalhead-C61RwL" src="./assets/img/logo-tropical-1@1x.png" /></a>
        <a (click)="navigate('urbano')" style="pointer-events: all;" ><img class="logourbanhead-C61RwL" src="./assets/img/logo-urban-1@1x.png" /></a>
        <a (click)="navigate('top40')" style="pointer-events: all;">
          <img class="logotus-exitoshead-C61RwL"
            src="./assets/img/e4e2f5b5d42ef38e2416f71dabee4063-tusexitosfmlogoforwebsite--2--1@1x.png"
        /></a>
        <a (click)="navigate('top40') "  style="pointer-events: all;"
          ><img  class="tus-exitos--no-shadow-C61RwL" src="./assets/img/tus-exitos-top-40-no-shadow@1x.png"
        /></a>
        <a (click)="navigate('tropical') "  style="pointer-events: all;"
          ><img class="tus-exitos--no-shadow-VMr6Om" src="./assets/img/tus-exitos-tropical-no-shadow@1x.png"
        /></a>
        <a (click)="navigate('urbano') "  style="pointer-events: all;"
          ><img class="tus-exitos--no-shadow-mzXdH9" src="./assets/img/tus-exitos-urban-no-shadow@1x.png"
        /></a>
        <div class="navigationbar-C61RwL"></div>
        <img class="logonav-C61RwL" src="./assets/img/logoNav.png" />
        <div class="navmenu-C61RwL">
          <div class="home-RYkzXP roboto-normal-delta-23px">Home</div>
          <a href="web-1920-2.html"><div class="estaciones-RYkzXP roboto-normal-delta-23px">Estaciones</div></a>
          <div class="charts-RYkzXP roboto-normal-delta-23px">Charts</div>
          <div class="blog-RYkzXP roboto-normal-delta-23px">Blog</div>
          <a href="web-1920-2.html"><img class="navdown-arrow-RYkzXP" src="./assets/img/nav-downarrow@1x.png" /></a>
        </div>
        <img class="greenline-C61RwL" src="./assets/img/green-line@1x.png" />
        <img class="headerselect1-C61RwL" src="./assets/img/header-select1@1x.png" />
        <img class="headerselect2-C61RwL" src="./assets/img/header-select1@1x.png" />
        <img class="headerselect3-C61RwL" src="./assets/img/header-select1@1x.png" />
        <img class="headerselect4-C61RwL" src="./assets/img/header-select1@1x.png" />
        <div class="top40about-C61RwL lato-normal-white-30px">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
          dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
          clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
          consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
          takimata sanctus est Lorem ipsum dolor sit amet.<br />Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
          sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos
          et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
          ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
          invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
          dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.<br />Lorem
          ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
          magna
        </div>
        <div class="tropicalabout-C61RwL lato-normal-white-30px">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
          dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
          clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
          consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
          takimata sanctus est Lorem ipsum dolor sit amet.<br />Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
          sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos
          et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
          ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
          invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
          dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.<br />Lorem
          ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
          magna
        </div>
        <div class="urbanoabout-C61RwL lato-normal-white-30px">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
          dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
          clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
          consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
          takimata sanctus est Lorem ipsum dolor sit amet.<br />Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
          sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos
          et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
          ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
          invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
          dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.<br />Lorem
          ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
          magna
        </div>
        <a (click)="navigate('top40')"  style="pointer-events: all;"><img class="top40play-C61RwL" src="./assets/img/icon-ionic-ios-play-circle@1x.png" /></a>
        <a (click)="navigate('tropical')"  style="pointer-events: all;"><img class="tropicalplay-C61RwL" src="./assets/img/icon-ionic-ios-play-circle-1@1x.png" /></a>
        <a (click)="navigate('urbano')"  style="pointer-events: all;"><img class="urbanoplay-C61RwL" src="./assets/img/icon-ionic-ios-play-circle-2@1x.png" /></a>
        <div class="rectangletop40-C61RwL dove-gray-border-1px"></div>
        <div class="rectangletropical-C61RwL dove-gray-border-1px"></div>
        <div class="rectangleurbano-C61RwL dove-gray-border-1px"></div>
      </div>
    </div>


  </body>
</html>
