<!-- <p>top40 works!</p>
<button style="width: 300px; height: 200px;" (click)="play40()"></button>
<button style="width: 300px; height: 200px;" (click)="stop40()"></button>
<h2 class="songTitle" *ngIf="pic">{{song.title}}</h2> -->


<!DOCTYPE html>
<html>
  <head>
    <meta charset="UTF-8" />
    <!-- <meta name="viewport" content="width=1920, maximum-scale=1.0" />
    <link rel="shortcut icon" href="./assets/img/favicon.png" />
    <meta name="og:type" content="website" /> -->
    <meta name="twitter:card" content="photo" />
    <!-- <link rel="stylesheet" type="text/css" href="css/web-1920-3.css" /> -->
    <!-- <style>
      @import url("https://fonts.googleapis.com/css?family=Lato:300,700,400|Roboto:400");

      .component-wrapper a,
      .screen a {
        text-decoration: none;
        display: contents;
      }

      .full-width-a {
        width: 100%;
      }

      .full-height-a {
        height: 100%;
      }

      .screen textarea:focus,
      .screen input:focus {
        outline: none;
      }

      .screen *,
      .component-wrapper * {
        box-sizing: border-box;
      }

      .screen div {
        -webkit-text-size-adjust: none;
      }

      .container-center-vertical,
      .container-center-horizontal {
        pointer-events: none;
        display: flex;
        flex-direction: row;
        padding: 0;
        margin: 0;
      }

      .container-center-vertical {
        align-items: center;
        height: 100%;
      }

      .container-center-horizontal {
        justify-content: center;
        width: 100%;
      }

      .container-center-vertical > *,
      .container-center-horizontal > * {
        pointer-events: auto;
        flex-shrink: 0;
      }

      .component-wrapper,
      .screen {
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-all;
        word-break: break-word;
      }

      .auto-animated div {
        opacity: 0;
        position: absolute;
        --z-index: -1;
      }

      .auto-animated .container-center-vertical,
      .auto-animated .container-center-horizontal {
        opacity: 1;
      }

      .overlay {
        position: absolute;
        opacity: 0;
        display: none;
        top: 0;
        width: 100%;
        height: 100%;
        position: fixed;
      }

      .animate-appear {
        opacity: 0;
        display: block;
        animation: reveal 0.3s ease-in-out 1 normal forwards;
      }

      .animate-disappear {
        opacity: 1;
        display: block;
        animation: reveal 0.3s ease-in-out 1 reverse forwards;
      }

      .animate-nodelay {
        animation-delay: 0s;
      }

      @keyframes reveal {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
      .align-self-flex-start {
        align-self: flex-start;
      }
      .align-self-flex-end {
        align-self: flex-end;
      }
      .align-self-flex-center {
        align-self: center;
      }
      .valign-text-middle {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .valign-text-bottom {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
      input:focus {
        outline: none;
      }
      .component-wrapper,
      .component-wrapper * {
        pointer-events: none;
      }

      .component-wrapper a *,
      .component-wrapper a,
      .component-wrapper input,
      .component-wrapper video,
      .component-wrapper iframe,
      .listeners-active,
      .listeners-active * {
        pointer-events: auto;
      }

      .hidden,
      .hidden * {
        visibility: hidden;
        pointer-events: none;
      }

      .smart-layers-pointers,
      .smart-layers-pointers * {
        pointer-events: auto;
        visibility: visible;
      }

      .component-wrapper.not-ready,
      .component-wrapper.not-ready * {
        visibility: hidden !important;
      }

      .listeners-active-click,
      .listeners-active-click * {
        cursor: pointer;
      }
    </style> -->
    <!-- <meta name="author" content="AnimaApp.com - Design to code, Automated." /> -->
  </head>
  <body style="margin: 0">

    <!-- <input type="hidden" id="anPageName" name="page" value="web-1920-3" /> -->
    <div class="container-center-horizontal">
      <div class="homePage screen" >
        <div class="bgGray"></div>
        <!-- <img *ngIf="pic" class="bgPic" src="{{picUrl}}"/>
          <img *ngIf="!pic" class="bgPic" src="./assets/img/album-top40.png" /> -->
          <img *ngIf="pic" class="albumPic" src="{{picUrl}}" />
          <img *ngIf="!pic" class="albumPic" src="./assets/img/album-top40.png" />
          <div class="songinfo">
            <!-- <img class="aa1c8b77f3-000x1000x1-C61RwL" src="./assets/img/aa1c8b77f382d4d32ad97002ab823680-1000x1000x1@1x.png" /> -->
            <div class="songTitle" *ngIf="pic">{{song.title}}</div>
            <div class="songArtist" *ngIf="pic">{{song.artist}}</div>
            <div class="songAlbum lato-normal-white-34px"*ngIf="pic" >{{albumName}}</div>
            <img class="PausePlay" src="./assets/img/icon-material-pause-circle-filled@1x.png" />
          </div>
        <img
          class="logoNavigation"
          src="./assets/img/logotus.png"
        />
        <div class="navigationMenu">
          <a (click)="navigate('home')"
            ><div class="home roboto-normal-delta-23px">Home</div></a
          >
          <a href="web-1920-2.html" onclick="window.event.stopPropagation()"
            ><div class="estaciones roboto-normal-delta-23px">Estaciones</div></a
          >
          <div class="charts roboto-normal-delta-23px">Charts</div>
          <div class="blog roboto-normal-delta-23px">Blog</div>

          <!-- <a href="web-1920-2.html" onclick="window.event.stopPropagation()"
            ><img class="polygon-1-GJws1M" src="./assets/img/polygon-1-1@1x.png"
          /></a> -->
        </div>
        <a href="web-1920-4.html" onclick="window.event.stopPropagation()"
          ><img class="tropicalCard" src="./assets/img/tropicalSmallCard.png"
        /></a>
        <a href="web-1920-5.html" onclick="window.event.stopPropagation()"
          ><img class="urbanoCard" src="./assets/img/urbanoSmallCard.png"
        /></a>
        <div class="titleEstaciones lato-normal-white-47px">Estaciones</div>
        <img class="PausePlay" src="./assets/img/icon-material-pause-circle-filled@1x.png" />
        <div class="now-playing lato-normal-gravel-21px">Now Playing…</div>
      </div>
    </div>
  </body>
</html>
