import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/Services/data.service';
import { NavigationStart, Router, RouterModule } from "@angular/router";
import {ResponsiveServiceService} from 'src/app/Services/responsive-service.service'

@Component({
  selector: 'app-tropical',
  templateUrl: './tropical.component.html',
  styleUrls: ['./tropical.component.css']
})
export class TropicalComponent implements OnInit {
  play: boolean = true;
  song :any;
  pic : boolean = false;
  stationUrl = "http://68.235.35.243:8003/live"
  stationName = "Tropical"
  albumName: string;
  songs: any[];
  picUrl : string;
  showMenu: boolean = false;
  interval;
  timeLeft: number = 7;
  loading: boolean = true;
  AdPic: any;
  AdPicUrl: string;
  longBannerURL: any
  public isMobile: Boolean;

  constructor(private dataservice :DataService, private router: Router, private responsiveService: ResponsiveServiceService) {
    this.longBannerURL = this.dataservice.PicUrl2;
    router.events.subscribe((event: NavigationStart) => {
      if(event.navigationTrigger === 'popstate'){
        this.dataservice.Positive.stop()
        this.dataservice.Urbano.stop()
        this.dataservice.Tropical.stop()
        this.dataservice.Top40.stop()
      }
    })
   }

  ngOnInit(): void {
    this.dataservice.setStations();
    this.startTimer();
this.onResize();
    if(this.dataservice.stationPlaying != this.stationName){
      this.playTropical();
      this.dataservice.Urbano.stop()
      this.dataservice.Top40.stop()
      this.dataservice.Positive.stop()
      this.dataservice.Mexicano.stop()
      this.dataservice.stationPlaying = this.stationName;

      this.getPlayTrack()
    }
    else{
      this.dataservice.Urbano.stop()
      this.dataservice.Top40.stop()
      this.dataservice.Positive.stop()

    }
  }

  ngAfterViewInit(){
    this.responsiveService.checkWidth()
  //  this.onResize();

    setInterval(() => this.getPlayTrack() , 1000);
    this.play = true;
    setInterval(() => this.getPic() , 3000); // TIMER TO SEARCH ADPIC

  }
  getPic(){
    this.longBannerURL = this.dataservice.PicUrl2;
  }

  async redirect(){
    let redirect : string = this.dataservice.PicRedirects2
    this.AdPicUrl = this.dataservice.PicRedirects2[0];
    await window.open(this.AdPicUrl, "_blank");
    //await Browser.open({ url: this.dataservice.PicRedirects2[0]  });
  }
  getBannerURL(){    //funcion del banner slim------------------------------------------<
    this.longBannerURL = this.dataservice.PicUrl2;
  }
  playTropical(){
    this.dataservice.Top40.stop();
    this.dataservice.Urbano.stop()
    this.dataservice.Positive.stop();
    this.dataservice.Mexicano.stop();

    this.dataservice.Tropical.play()
  }
  stopTropical(){
   this.dataservice.Tropical.stop();

  }
  playPause(){
    this.play = !this.play
    if(this.play == true){
      this.dataservice.Tropical.play();
      //console.log('play')


    }
    else{
     // console.log('pause')
      this.dataservice.Tropical.stop();

    }



  }
  getPlayTrack(){

    this.dataservice.getTropicalInfo().subscribe(
      data => {
        let tempSong : any[];
        tempSong = data["data"];
        this.song = tempSong[0].track
        //console.log(this.song);

        if(this.song.album == "Unknown" ||this.song.album == "" || this.song.album == "unknown" ){
          this.picUrl = '/assets/img/album-tropical.png'
          this.pic = true;
         // console.log(this.picUrl);
          this.albumName = "Single";

        }

        else{
          this.MakePic();
          this.albumName = this.song.album;

        }

      },

      err => {
       // console.log(err);
      },
      () => {
        //console.log('done');


      }
      );

    }

    MakePic(){
      let tempString;
      tempString = this.song.imageurl
      //console.log("hello");
      tempString = tempString.slice(0,-13)
      //console.log(tempString);
      this.picUrl = tempString + "400x400bb.jpg"
      //console.log(this.picUrl);
      this.checkPic();


    }

    checkPic(){

      this.dataservice.CheckPic(this.picUrl).subscribe(
        data => {
          let tempSong : any[];
          tempSong = data["data"];

          //console.log(data);



        },

        err => {
          //console.log(err.status);
          if(err.status  != 200){
            this.picUrl = "/assets/img/album-tropical.png"
            //console.log(this.picUrl);
            this.pic = true;}
            else{
              this.pic = true;
            }
          },
          () => {
           // console.log('done');

          }
          );

        }


        navigate(page: string){


            this.stopTropical();
            this.router.navigate(['/' + page]);


          }
          showMenuBar(){
            this.showMenu = !this.showMenu;
          }




          startTimer() {
            this.interval = setInterval(() => {
              if(this.timeLeft > 0) {
                this.timeLeft--;
              } else {
                this.loading = false;
                clearInterval(this.interval);
              }
            },1000)
          }

    onResize() {
      this.responsiveService.getMobileStatus().subscribe(isMobile => {
        this.isMobile = isMobile;
      });
    }

}

