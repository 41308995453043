import { Injectable, NgZone, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {Howl, Howler} from 'howler';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireDatabase} from '@angular/fire/database';



@Injectable({
  providedIn: 'root'
})
export class DataService implements OnInit {
  Top40 : any;
  Tropical :any;
  Urbano :any;
  Positive: any;
  Mexicano: any;
  Fuzed: any;
  Empty: any;
  stationPlaying : any;
  apiKey = "AIzaSyARGXhOP6rOacPyCVWUG5LF2pPKBmK_tGE";
  PicUrl: any;
  PicUrl2: any
  PicUrl3: any;

  PicRedirects : any;
  PicRedirects2: any;
  picRedirects3: any;
  picID:any;
  picID2: any;
  slimPicID:any;
  picID3: any;
  adsCount: any;



  constructor(private http : HttpClient,
    private ngzone : NgZone,  private afs : AngularFireStorage,
    private afd : AngularFireDatabase,) {

    }
    ngOnInit(){


      //this.setEmpty();


    }

    getTop40Info(){
      return this.http.get("http://server.servistreaming.com:2199/rpc/jose/streaminfo.get")

    }

    getTropicalInfo(){
      return this.http.get("http://server20.servistreaming.com:2199/rpc/jose/streaminfo.get")
    }

    getUrbanoInfo(){
      return this.http.get("http://server10.servistreaming.com:2199/rpc/tusexitosurbano/streaminfo.get")
    }

    getPositiveInfo(){
      return this.http.get("http://server.servistreaming.com:2199/rpc/tusexitosgospel/streaminfo.get")
    }
    getMexicanoInfo(){
      return this.http.get("http://server20.servistreaming.com:2199/rpc/tusexitosmex/streaminfo.get")
    }

    setEmpty(){

      this.Empty = new Howl({
        src: [""],
        html5: true,
        format: ['mp3', 'aac']

      });

    }
    setStations(){
      this.bannerRotation();
      this.bannerRotation2()
      this.rotation3()
      setInterval(() =>this.bannerRotation(), 8000); // TIMER TO SEARCH FOR SONG INFO
      setInterval(() =>this.bannerRotation2(), 8000); // TIMER TO SEARCH FOR SONG INFO
      //setInterval(() =>this.rotation3(), 3000); // TIMER TO SEARCH FOR SONG INFO

      this.Top40 = new Howl({
       // src: ["http://146.71.118.220:35022/live"],
        src: ["https://server7.servistreaming.com/proxy/tusexitosc?mp=/stream"],
        html5: true,
        format: ['mp3', 'aac'],
       // autoplay: true

      });

      this.Tropical = new Howl({
        src: ["https://server20.servistreaming.com:9016/stream"],
        html5: true,
        format: ['mp3', 'aac']

      });

      this.Urbano = new Howl({
        src: ["https://server20.servistreaming.com:9057/stream"],
        html5: true,
        format: ['mp3', 'aac']

      });
      this.Positive = new Howl({
        src: ["https://server7.servistreaming.com/proxy/tusexitosgospel?mp=/stream"],
        html5: true,
        format: ['mp3', 'aac']

      });
      this.Mexicano = new Howl({
        src: ["https://server20.servistreaming.com:9048/stream"],
        html5: true,
        format: ['mp3', 'aac']

      });

      this.Fuzed = new Howl({
        src: ["https://azuracast.fuzedclubradio.com/radio/8000/stream"],
        html5: true,
        format: ['mp3', 'aac']
      })

    }



      getTop40LastSongs(){

        return this.http.get("http://server.servistreaming.com:2199/recentfeed/jose/json/");

      }

      getTropicalLastSongs(){

        return this.http.get("http://server10.servistreaming.com:2199/recentfeed/jose/json/");

      }

      getUrbanoLastSongs(){

        return this.http.get("http://server10.servistreaming.com:2199/recentfeed/tusexitosurbano/json/");

      }




      CheckPic(picUrl){
        return this.http.get(picUrl);
      }



      getPic(artist: string, trackName : string){
        const httpOptions = new HttpHeaders({
              'Authorization' : 'Bearer ${"token=fnejPIfLLeWzKOgwNMDpvrcHEBMNjLxVvFrnyWov"}'

          })

        // let header = {
        //  headers: new HttpHeaders()
        // .set('Authorization',  `Bearer ${"Discogs token=fnejPIfLLeWzKOgwNMDpvrcHEBMNjLxVvFrnyWov"}`)
        // }


        return this.http.get("https://api.discogs.com/database/search?q="+artist+ " - " + trackName +"&key=kcZMcBIjKjVqTZonFToB&secret=EmrZZcDRowCOWqWabiiBJuEQLBbGflrm")
          }

          getPlaylistsForChannel(channel) {
            return this.http.get('https://www.googleapis.com/youtube/v3/playlists?key=' + this.apiKey + '&channelId=' + channel + '&part=snippet,id&maxResults=20')

          }

          getListVideos(listId) {
            return this.http.get('https://www.googleapis.com/youtube/v3/playlistItems?key=' + this.apiKey + '&playlistId=' + listId +'&part=snippet,id&maxResults=20')

          }

          bannerRotation(){
            let randomNum
            let bannerNum
             this.afs.storage.ref('ads/').listAll().then((res) =>{
                 bannerNum = res.items.length;
             //console.log(res.items.length);

             randomNum = Math.floor(Math.random() * Math.floor(bannerNum) + 1)
            if(this.picID != randomNum){
                this.picID = randomNum;
                this.getDynamicBanner()
            }
            else{
              this.bannerRotation();
            }
            });

          }

          bannerRotation2(){

            let randomNum
            let bannerNum
             this.afs.storage.ref('longAds/').listAll().then((res) =>{
                 bannerNum = res.items.length;
           //  console.log(res.items.length);

             randomNum = Math.floor(Math.random() * Math.floor(bannerNum) + 1)
            if(this.picID2 != randomNum){
                this.picID2 = randomNum;
                this.getDynamicBanner2()
            }
            else{
              this.bannerRotation2();
            }
            });
          }

          rotation3(){
            let randomNum
            let bannerNum
             this.afs.storage.ref('stationAds/').listAll().then((res) =>{
                 bannerNum = res.items.length;
           //  console.log(res.items.length);

             randomNum = Math.floor(Math.random() * Math.floor(bannerNum) + 1)
            if(this.picID3 != randomNum){
                this.picID3 = randomNum;
                this.getDynamicBanner3()
            }
            else{
              this.rotation3();
            }
            });


          }
          getDynamicBanner(){
            let id : number = 1;

            this.afs.storage.ref('ads/').child("/" + this.picID+ ".png").getDownloadURL().then((url) =>{

              this.ngzone.run(() =>{
              this.PicUrl = url;
              this.getBannerUrl()


            })

             })



          }

          getDynamicBanner2(){

            this.afs.storage.ref('longAds/').child("/" + this.picID2+ ".jpg").getDownloadURL().then((url) =>{

              this.ngzone.run(() =>{
              this.PicUrl2 = url;
             // console.log("get photo " +this.picID2)


              this.getBannerUrl2()
            })

             })

          }
          getDynamicBanner3(){

            this.afs.storage.ref('stationAds/').child("/" + this.picID3+ ".jpg").getDownloadURL().then((url) =>{

              this.ngzone.run(() =>{
              this.PicUrl3 = url;
             // console.log("get photo " +this.picID2)


              this.getBannerUrl3()
            })

             })

          }

          getBannerUrl(){

            this.afd.list('ads/' + this.picID).valueChanges().subscribe(
              data =>{

                this.PicRedirects = data;

              }


            )



          }

          getBannerUrl2(){
            this.afd.list('longAds/' + this.picID2).valueChanges().subscribe(
              data =>{

                this.PicRedirects2 = data;
                //console.log("get banner " + this.picID2);

              }


            )
          }
          getBannerUrl3(){
            this.afd.list('stationAds/' + this.picID3).valueChanges().subscribe(
              data =>{

                this.picRedirects3 = data;
              console.log("get banner " + this.picID3);

              }


            )
          }

}